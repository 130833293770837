<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-card>
      <editHeader
        ref="editHeader"
        :isShow="isShow"
        :btn="btn"
        :strForm="optnForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('optnForm')"
      />
      <el-form
        ref="optnForm"
        :model="optnForm"
        :rules="rules"
        :disabled="isShow"
        label-width="120px"
        size="mini"
        class="vg_pt_16 vd_mtb vg_mt_16"
      >
        <el-row>
          <el-col :md="8">
            <el-form-item label="数据名称" prop="perm_name">
              <el-input v-model="optnForm.perm_name" placeholder="数据名称" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="vd_button">
          <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
          <el-button type="danger" plain size="small" @click="delTeam()">删除</el-button>
        </div>
        <el-table ref="cntt" :data="optnForm.optn_cntt_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="48" align="center"></el-table-column>
          <el-table-column label="序号" width="48" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param1">
            <template slot-scope="scope">
              <el-form-item label-width="0" :prop="'optn_cntt_list.' + scope.$index + '.param1'" :rules="[{ required: true }]">
                <el-input
                  @change="changeInput(scope)"
                  v-model="optnForm.optn_cntt_list[scope.$index].param1"
                  maxlength="30"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param2" v-if="optnForm.param2">
            <template slot-scope="scope">
              <el-form-item label-width="0" :rules="[{ required: true }]">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param2" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param3" v-if="optnForm.param3">
            <template slot-scope="scope">
              <el-form-item label-width="0" :rules="[{ required: true }]">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param3" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param4" v-if="optnForm.param4">
            <template slot-scope="scope">
              <el-form-item label-width="0" :rules="[{ required: true }]">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param4" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column :label="optnForm.param5" v-if="optnForm.param5">
            <template slot-scope="scope">
              <el-form-item label-width="0" :rules="[{ required: true }]">
                <el-input v-model="optnForm.optn_cntt_list[scope.$index].param5" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
export default {
  name: 'OptnEdit',
  components: {
    editHeader
  },
  data() {
    return {
      optnForm: {
        optn_id: '',
        perm_id: '',
        perm_name: '',
        param1: '',
        param2: null,
        param3: null,
        param4: null,
        param5: null,
        optn_cntt_list: []
      },
      rules: {
        perm_name: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      key: 0,
      optn_cntt_copy: [],
      selectionsList: [],
      isShow: true,
      btn: {},
      copyForm: [],
      loadingFlag: true
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.$refs.editHeader.stateFlag = false;
  },
  methods: {
    initData() {
      if (this.$route.query.perm_id) {
        this.loadingFlag = true;
        this.getOptnInfo();
      }
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //获取表单信息
    getOptnInfo() {
      get(optnAPI.getOptnByPermId, { perm_id: this.$route.query.perm_id })
        .then(res => {
          if (res.data.code === 0) {
            this.optnForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.optnForm.optn_cntt_list.forEach(item => {
              item.k = this.key;
              this.key++;
            });
            this.optn_cntt_copy = this.optnForm.optn_cntt_list;
            this.copyForm = Object.assign(this.copyForm, this.optnForm.optn_cntt_list);
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //添加
    addRow() {
      let item = {
        param1: '',
        destroy_flag: 0,
        k: this.key
      };
      this.key = this.key + 1;
      this.optnForm.optn_cntt_list.push(item);
      this.selectionsList = [];
    },
    // 判断是否重复
    changeInput(val) {
      let arr = this.optnForm.optn_cntt_list.filter(item => {
        if (item.param1 === val.row.param1) {
          return item;
        }
      });
      if (arr.length >= 2) {
        this.$message.warning('数据重复，请重新填写');
        this.optnForm.optn_cntt_list[val.$index].param1 = null;
        return;
      }
    },
    //多选
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //多选删除
    delTeam() {
      if (this.selectionsList.length > 0) {
        for (let i = 0; i < this.selectionsList.length; i++) {
          this.optnForm.optn_cntt_list.forEach(item => {
            if (item.k === this.selectionsList[i].k) {
              let currentIndex = this.optnForm.optn_cntt_list.indexOf(item);
              this.optnForm.optn_cntt_list.splice(currentIndex, 1);
            }
          });
        }
      } else {
        this.$message({
          type: 'warning',
          message: '至少选择一条数据'
        });
      }
    },
    //提交表单
    submit(formName) {
      let flag = this.optnForm.optn_cntt_list.some(item => {
        return item.param1 == '';
      });
      if (flag) {
        let name = '';
        return this.$message.error(this.optnForm.perm_name + '名称不可为空');
      }
      this.$confirm('确定提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      this.optnForm.optn_id = this.$route.query.form_id;
      let optnForm = {};
      let optnFi = [];
      optnForm = Object.assign(optnForm, this.optnForm);
      optnFi = Object.assign(optnFi, this.optn_cntt_copy);
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        if (optnFi.indexOf(this.copyForm[i]) !== -1) {
          this.copyForm.splice(i, 1);
        }
      }
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        this.copyForm[i].destroy_flag = 1;
      }
      optnFi = optnFi.concat(this.copyForm);
      optnForm.optn_cntt_list = optnFi;
      post(optnAPI.editContent, optnForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.initData();
            this.isShow = true;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_mtb {
  border-top: #c0c4cc solid 1px;
}
</style>
